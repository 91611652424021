
import axios from "axios";

//const API_URL =  "http://localhost:65437";
const API_URL = "http://www.adminapi.royaleducation.net";

export class APIService {

  constructor() {}

  getStudents() {
    const request = {
      url: API_URL + "/api/Student",
      method: "get",
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");

    return axios(request).then((response) => response.data);
  }

  addStudent(data) {
    const request = {
      url: API_URL + "/api/Student",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteStudent(data) {
    const request = {
      url: API_URL + "/api/Student/DeleteStudent",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  updateStudent(data) {
    const request = {
      url: API_URL + "/api/Student/UpdateStudent",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  searchStudents(classId, searchkey) {
    let query = [];
    if (classId != 0) query.push(`classId=${classId}`);

    if (searchkey) query.push(`searchKey=${searchkey}`);

    const request = {
      url: `${API_URL}/api/Student/SearchStudents?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  getSubjects() {
    const request = {
      url: API_URL + "/api/Subject",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  searchSubjects(search) {
    let query = [];
    if (search) query.push(`search=${search}`);
    const request = {
      url: `${API_URL}/api/Subject/SearchSubjects?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addSubject(data) {
    const request = {
      url: API_URL + "/api/Subject",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  updateSubject(data) {
    const request = {
      url: API_URL + "/api/Subject/UpdateSubject",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteSubject(data) {
    const request = {
      url: API_URL + "/api/Subject/DeleteSubject",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getClasses() {
    const request = {
      url: API_URL + "/api/Class",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addClass(data) {
    const request = {
      url: API_URL + "/api/Class",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  updateClass(data) {
    const request = {
      url: API_URL + "/api/Class/UpdateClass",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteClass(data) {
    const request = {
      url: API_URL + "/api/Class/DeleteClass",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getStudyYears() {
    const request = {
      url: API_URL + "/api/StudyYear",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addStudyYear(data) {
    const request = {
      url: API_URL + "/api/StudyYear",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteStudyYear(data) {
    const request = {
      url: API_URL + "/api/StudyYear/DeleteStudyYear",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  updateStudyYear(data) {
    const request = {
      url: API_URL + "/api/StudyYear/UpdateStudyYear",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  updateTimeProgramDetail(data) {
    const request = {
      url: API_URL + "/api/TimeProgram/UpdateTimeProgramDetails",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  searchClasses(search) {
    let query = [];
    if (search) query.push(`search=${search}`);
    const request = {
      url: `${API_URL}/api/Class/SearchClasses?${query.join("&")}`,
      method: "get",
    };

    return axios(request).then((response) => response.data);
  }

  getThisYearTimePro() {
    const request = {
      url: API_URL + "/api/TimeProgram",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  getTestPrograms() {
    const request = {
      url: API_URL + "/api/TestProgram",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  addTestProgram(data) {
    const request = {
      url: API_URL + "/api/TestProgram",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  deleteTestProgram(data) {
    const request = {
      url: API_URL + "/api/TestProgram/DeleteTestProgram",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  updateTestProgram(data) {
    const request = {
      url: API_URL + "/api/TestProgram/UpdateTestProgram",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  searchTestProgram(classId, searchkey) {
    let query = [];
    if (classId != 0) query.push(`classId=${classId}`);

    if (searchkey) query.push(`searchKey=${searchkey}`);

    const request = {
      url: `${API_URL}/api/TestProgram/SearchTestPrograms?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  updateTestProgramDetail(data) {
    const request = {
      url: API_URL + "/api/TestProgram/UpdateTestProgramDetails",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getAttendClasses() {
    const request = {
      url: API_URL + "/api/StudentAttendance",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  getAttendanceInMonth(month, classId) {
    const request = {
      url:
        API_URL +
        "/api/StudentAttendance/GetStudentAttedInMonth?month=" +
        month +
        "&classId=" +
        classId,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  getAttendanceDate(classId, month, year) {
    const request = {
      url:
        API_URL +
        "/api/StudentAttendance/GetAttendanceDate?classId=" +
        classId +
        "&month=" +
        month +
        "&year=" +
        year,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  getStudentInClassForAttend(classId) {
    const request = {
      url:
        API_URL +
        "/api/StudentAttendance/GetStudentForAttend?classId=" +
        classId,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addAttendance(data) {
    const request = {
      url: API_URL + "/api/StudentAttendance",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteAttendance(data) {
    const request = {
      url: API_URL + "/api/StudentAttendance/DeleteAttendance",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getTestResults() {
    const request = {
      url: API_URL + "/api/TestResult",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  searchTestResults(classId, subjectId, searchkey) {
    let query = [];
    if (classId != 0) query.push(`classId=${classId}`);
    if (subjectId != 0) query.push(`subjectId=${subjectId}`);
    if (searchkey) query.push(`searchKey=${searchkey}`);

    const request = {
      url: `${API_URL}/api/TestResult/SearchTestResults?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addTest(data) {
    const request = {
      url: API_URL + "/api/TestResult",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteTestResult(data) {

    const request = {
      url: API_URL + "/api/TestResult/DeleteTestResult",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  UpdateTestResult(data) {
    const request = {
      url: API_URL + "/api/TestResult/UpdateTestResult",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getStudentInClassForResult(testId) {
    const request = {
      url: API_URL + "/api/TestResult/GetStudentForResult?testId=" + testId,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  saveMark(data) {
    const request = {
      url: API_URL + "/api/TestResult/SaveMark",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getGeneralNotes() {
    const request = {
      url: API_URL + "/api/GeneralNote",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  addNote(data) {
    const request = {
      url: API_URL + "/api/GeneralNote",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  deleteNote(data) {
    const request = {
      url: API_URL + "/api/GeneralNote/DeleteNote",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  sendNote(data) {
    const request = {
      url: API_URL + "/api/GeneralNote/SendNote",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  updateNote(data) {
    const request = {
      url: API_URL + "/api/GeneralNote/UpdateNote",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getStatistics() {
    const request = {
      url: API_URL + "/api/Statistics",
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  GetTopThrees(data) {
    let query = [];
    if (data.month != 0) query.push(`month=${data.month}`);

    if (data.stage) query.push(`stage=${data.stage}`);
    const request = {
      url: `${API_URL}/api/Statistics/GetTopThrees?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  GetStatisticsDetails( month, classId)
  {
    let query = [];
    if (month != 0) query.push(`month=${month}`);
    if (classId!=0) query.push(`classId=${classId}`);
    const request = {
      url: `${API_URL}/api/Statistics/GetStatisticsDetails?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }
  releaseStatistics(data) {
    const request = {
      url: API_URL + "/api/Statistics",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  deleteStatistics(data) {
    const request = {
      url: API_URL + "/api/Statistics/DeleteStatistics",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  getStudentAttendances(studentName,classId,fromDate,toDate)
  {
    let query = [];
    if(studentName==null) studentName="";
    query.push(`studentName=${studentName}`);
    if(classId!=null) query.push(`classId=${classId}`);
    if(fromDate!=null) query.push(`fromDate=${fromDate}`);
    if(toDate!=null) query.push(`toDate=${toDate}`);
  
    const request = {
      url: `${API_URL}/api/StudentAttendance?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  getStudentInstallment(studentId)
  {
    let query = [];
    if (studentId != 0) query.push(`studentId=${studentId}`);
  
    const request = {
      url: `${API_URL}/api/StudentInstallment?${query.join("&")}`,
      method: "get",
    };
    return axios(request).then((response) => response.data);
  }

  deleteStudentInstallment(data) {
    const request = {
      url: API_URL + "/api/StudentInstallment/DeleteStudentInstallment",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }
  UpdateStudentInstallment(data) {
    const request = {
      url: API_URL + "/api/StudentInstallment/UpdateStudentInstallment",
      data: data,
      method: "post",
    };
    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

  
  addStudentInstallment(data) {
    const request = {
      url: API_URL + "/api/StudentInstallment",
      data: data,
      method: "post",
    };

    return new Promise((resolve, reject) => {
      axios(request)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

}
